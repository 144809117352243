


























import { debounceProcess } from '@/helpers/debounce'
import { QrcodeStream } from 'vue-qrcode-reader'
import Vue from 'vue'
export default Vue.extend({
  components: {
    QrcodeStream
  },
  data () {
    this.setInnerWidth = debounceProcess(this.setInnerWidth, 200)
    return {
      camera: 'auto',
      result: null,
      showScanConfirmation: false,
      innerWidth: 0 as null | number
    }
  },
  methods: {
    async onDecode (content) {
      this.result = content

      this.pause()
      await this.timeout(500)
      this.unpause()
    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

    handleCancel () {
      this.result = null
    },

    setInnerWidth (value: number | null) {
      this.innerWidth = value
      // debounceProcess(() => this.innerWidth = value, 200)
    },
  },
  mounted () {
    window.addEventListener('resize',(e: UIEvent)=> {
      const width = e.target as Window
      this.setInnerWidth(width.innerWidth)
    });
  },
})
