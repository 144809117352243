var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }
        },
        [
          _c("h2", [_vm._v("Scan Your QR")]),
          _c(
            "div",
            {
              style:
                _vm.innerWidth >= 500
                  ? "width: 50%;height:50%"
                  : "width: 100%;height:100%"
            },
            [
              _c(
                "qrcode-stream",
                { attrs: { camera: _vm.camera }, on: { decode: _vm.onDecode } },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showScanConfirmation,
                        expression: "showScanConfirmation"
                      }
                    ],
                    staticClass: "scan-confirmation"
                  })
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            keyboard: false,
            visible: _vm.result ? true : false
          },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v(" Return ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("a-icon", {
                staticStyle: {
                  fontSize: "1.75rem",
                  color: "#52c41a",
                  "vertical-align": "0",
                  "margin-right": "1rem"
                },
                attrs: { type: "check-circle" }
              }),
              _c("h2", [_vm._v("Scan Success")])
            ],
            1
          ),
          _c("a", { attrs: { href: _vm.result, target: "_blank" } }, [
            _vm._v(_vm._s(_vm.result))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }